import React, { useState } from 'react';
import {
    Container,
    Typography,
    TextField,
    Button,
    Snackbar,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    IconButton,
    Paper,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';
import AnimatedBox from '../../../../components/MotionChild/AnimatedBox';
import MapModal from '../../../../components/MapModal/MapModal';
import { addProduct } from '../../../../api/api';
import { useNavigate } from 'react-router-dom';
import { uploadRoomImage } from '../../../../api/api';
import { fetchAllProducts } from "../../../../service/redux/reducers/productSlice";

const generateTimeOptions = () => {
    const times = [];
    for (let hour = 0; hour < 24; hour++) {
        ['00', '30'].forEach((minute) => {
            const formattedHour = hour.toString().padStart(2, '0');
            times.push(`${formattedHour}:${minute}`);
        });
    }
    return times;
};

const TIME_OPTIONS = generateTimeOptions();

const FIELD_TYPES = [
    { value: 'text', label: 'Текстовое поле' },
    { value: 'boolean', label: 'Да/Нет' },
    { value: 'number', label: 'Числовое поле' },
    { value: 'list', label: 'Список' },
    { value: 'counter', label: 'Счетчик' },
];

const LIST_MODES = [
    { value: 'single', label: 'Одиночный выбор' },
    { value: 'multi', label: 'Множественный выбор' },
];

const AddRoomScreen = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { group_id } = useSelector((state) => state.auth);
    const [label, setLabel] = useState('');
    const [description, setDescription] = useState('');
    const [capacity, setCapacity] = useState('');
    const [openingHoursStart, setOpeningHoursStart] = useState('');
    const [openingHoursEnd, setOpeningHoursEnd] = useState('');
    const [coordinates, setCoordinates] = useState('55.751244,37.618423');
    const [address, setAddress] = useState('');
    const [isMapModalOpen, setIsMapModalOpen] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [imageLink, setImageLink] = useState('');
    const [dynamicFields, setDynamicFields] = useState([]);
    const [newFieldLabel, setNewFieldLabel] = useState('');
    const [newFieldType, setNewFieldType] = useState('');
    const [newFieldListMode, setNewFieldListMode] = useState('single');
    const [newFieldOptions, setNewFieldOptions] = useState('');

    const handleMapModalOpen = () => {
        setIsMapModalOpen(true);
    };

    const handleMapModalClose = (newAddressData) => {
        setIsMapModalOpen(false);
        if (newAddressData) {
            setAddress(newAddressData.address);
            setCoordinates(newAddressData.coordinates);
        }
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file && file.type.startsWith('image/') && file.size <= 10 * 1024 * 1024) {
            setSelectedFile(file);
            try {
                const link = await uploadRoomImage(file, (progressEvent) => {
                    console.log(
                        `Upload progress: ${Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        )}%`
                    );
                });
                setImageLink(link);
                alert('Файл успешно загружен!');
            } catch (error) {
                alert(error.message || 'Файл не загружен! Попробуйте еще раз');
            }
        } else {
            alert('Ваш файл превышает допустимый размер (не более 10MB).');
        }
    };

    const addDynamicField = () => {
        if (!newFieldLabel || !newFieldType) return;
        const field = {
            id: Date.now(),
            label: newFieldLabel,
            type: newFieldType,
            ...(newFieldType === 'list' && {
                listMode: newFieldListMode,
                options: newFieldOptions.split(',').map(opt => opt.trim()).filter(opt => opt),
            }),
        };
        setDynamicFields(prev => [...prev, field]);
        setNewFieldLabel('');
        setNewFieldType('');
        setNewFieldListMode('single');
        setNewFieldOptions('');
    };

    const removeDynamicField = (id) => {
        setDynamicFields(prev => prev.filter(field => field.id !== id));
    };

    const handleSave = async () => {
        const newRoom = {
            group_id,
            label,
            description,
            type: 'talk_room',
            image_link: imageLink,
            additionally: {
                capacity: Number(capacity),
                opening_hours: `C ${openingHoursStart} до ${openingHoursEnd}`,
                opening_hours_start: openingHoursStart,
                opening_hours_end: openingHoursEnd,
                coordinates,
                address,
                custom_fields: dynamicFields,
            },
        };

        setIsSubmitting(true);

        try {
            await addProduct(newRoom);
            setSnackbarOpen(true);
            dispatch(fetchAllProducts());
            setTimeout(() => {
                navigate('/adminpanel/products');
            }, 1500);
        } catch (error) {
            console.error('Error adding room:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <AnimatedBox delay={0.1}>
            <Container maxWidth="sm" sx={{ textAlign: 'center', paddingTop: '20px' }}>
                <Typography variant="h6" gutterBottom>
                    Добавить помещение
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 3 }}>
                    <TextField
                        label="Название"
                        value={label}
                        onChange={(e) => setLabel(e.target.value)}
                        fullWidth
                        variant="outlined"
                        required
                    />
                    <TextField
                        label="Описание"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        label="Вместимость"
                        value={capacity}
                        onChange={(e) => {
                            const value = e.target.value;
                            if (/^\d*$/.test(value)) {
                                setCapacity(value);
                            }
                        }}
                        fullWidth
                        variant="outlined"
                    />
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <FormControl fullWidth>
                            <InputLabel id="start-time-label">Начало работы</InputLabel>
                            <Select
                                labelId="start-time-label"
                                value={openingHoursStart}
                                label="Начало работы"
                                onChange={(e) => setOpeningHoursStart(e.target.value)}
                            >
                                {TIME_OPTIONS.map((time) => (
                                    <MenuItem key={time} value={time}>
                                        {time}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel id="end-time-label">Окончание работы</InputLabel>
                            <Select
                                labelId="end-time-label"
                                value={openingHoursEnd}
                                label="Окончание работы"
                                onChange={(e) => setOpeningHoursEnd(e.target.value)}
                            >
                                {TIME_OPTIONS.map((time) => (
                                    <MenuItem key={time} value={time}>
                                        {time}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Button variant="outlined" fullWidth onClick={handleMapModalOpen}>
                        Указать местоположение
                    </Button>
                    {address && (
                        <Typography variant="body2" color="text.secondary">
                            Адрес: {address}
                        </Typography>
                    )}
                    <input
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="image-upload-input"
                        type="file"
                        onChange={handleFileChange}
                    />
                    <label htmlFor="image-upload-input" style={{ width: '100%' }}>
                        <Button variant="outlined" component="span" fullWidth>
                            Выбрать изображение
                        </Button>
                    </label>
                    <Typography variant="body2" color="text.secondary" align="center">
                        (Рекомендуемый формат 4х3)
                    </Typography>
                    {selectedFile && (
                        <Typography variant="body2" color="text.secondary">
                            {selectedFile.name} ({(selectedFile.size / 1024 / 1024).toFixed(2)} MB)
                        </Typography>
                    )}
                    {dynamicFields.length > 0 && (
                        <Box sx={{ mt: 2 }}>
                            <Typography variant="subtitle1" gutterBottom>
                                Дополнительные значения
                            </Typography>
                            {dynamicFields.map((field) => (
                                <Paper
                                    key={field.id}
                                    elevation={1}
                                    sx={{ display: 'flex', alignItems: 'center', p: 1, mb: 1 }}
                                >
                                    <Box sx={{ flexGrow: 1, textAlign: 'left' }}>
                                        <Typography variant="body2">
                                            {field.label} ({FIELD_TYPES.find(opt => opt.value === field.type)?.label})
                                        </Typography>
                                        {field.type === 'list' && (
                                            <Typography variant="caption">
                                                {LIST_MODES.find(opt => opt.value === field.listMode)?.label} - [{field.options.join(', ')}]
                                            </Typography>
                                        )}
                                    </Box>
                                    <IconButton onClick={() => removeDynamicField(field.id)}>
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                </Paper>
                            ))}
                        </Box>
                    )}
                    <Paper elevation={2} sx={{ padding: 2, mt: 1, borderRadius: '12px' }}>
                        <Typography variant="subtitle1" gutterBottom>
                            Создать дополнительное поле
                        </Typography>
                        <TextField
                            label="Название поля"
                            value={newFieldLabel}
                            onChange={(e) => setNewFieldLabel(e.target.value)}
                            fullWidth
                            variant="outlined"
                            sx={{ mb: 2 }}
                        />
                        <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
                            <InputLabel id="field-type-label">Тип поля</InputLabel>
                            <Select
                                labelId="field-type-label"
                                value={newFieldType}
                                label="Тип параметра"
                                onChange={(e) => setNewFieldType(e.target.value)}
                            >
                                {FIELD_TYPES.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {newFieldType === 'list' && (
                            <>
                                <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
                                    <InputLabel id="list-mode-label">Тип списка</InputLabel>
                                    <Select
                                        labelId="list-mode-label"
                                        value={newFieldListMode}
                                        label="Тип списка"
                                        onChange={(e) => setNewFieldListMode(e.target.value)}
                                    >
                                        {LIST_MODES.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <TextField
                                    label="Варианты (через запятую)"
                                    value={newFieldOptions}
                                    onChange={(e) => setNewFieldOptions(e.target.value)}
                                    fullWidth
                                    variant="outlined"
                                    sx={{ mb: 2 }}
                                />
                            </>
                        )}
                        <Button variant="contained" onClick={addDynamicField} fullWidth>
                            Добавить поле
                        </Button>
                    </Paper>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={handleSave}
                        disabled={!label || isSubmitting || !imageLink}
                        sx={{ mt: 2 }}
                    >
                        Добавить помещение
                    </Button>
                </Box>
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={1500}
                    onClose={() => setSnackbarOpen(false)}
                    message="Помещение успешно добавлено!"
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                />
                {isMapModalOpen && (
                    <MapModal
                        open={isMapModalOpen}
                        onClose={handleMapModalClose}
                        currentCoordinates={coordinates}
                        currentAddress={address}
                    />
                )}
            </Container>
        </AnimatedBox>
    );
};

export default AddRoomScreen;
