import React, { useState } from 'react';
import {
    Container,
    Grid,
    Card,
    CardMedia,
    CardContent,
    Typography,
    Box,
    Button,
    Modal,
    TextField,
    Snackbar,
    IconButton,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Paper,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AnimatedBox from '../../../../components/MotionChild/AnimatedBox';
import MapModal from '../../../../components/MapModal/MapModal';
import { editProduct } from '../../../../api/api';
import { fetchAllProducts } from '../../../../service/redux/reducers/productSlice';

const FIELD_TYPES = [
    { value: 'text', label: 'Текстовое поле' },
    { value: 'boolean', label: 'Да/Нет' },
    { value: 'number', label: 'Числовое поле' },
    { value: 'list', label: 'Список' },
    { value: 'counter', label: 'Счетчик' },
];

const LIST_MODES = [
    { value: 'single', label: 'Одиночный выбор' },
    { value: 'multi', label: 'Множественный выбор' },
];

const generateTimeOptions = () => {
    const times = [];
    for (let hour = 0; hour < 24; hour++) {
        ['00', '30'].forEach(minute => {
            const formattedHour = hour.toString().padStart(2, '0');
            times.push(`${formattedHour}:${minute}`);
        });
    }
    return times;
};

const TIME_OPTIONS = generateTimeOptions();

const ProductList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const products = useSelector(state => state.products.products);
    const [editProductData, setEditProductData] = useState(null);
    const [isMapModalOpen, setIsMapModalOpen] = useState(false);
    const [newAddress, setNewAddress] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [newFieldLabel, setNewFieldLabel] = useState('');
    const [newFieldType, setNewFieldType] = useState('');
    const [newFieldListMode, setNewFieldListMode] = useState('single');
    const [newFieldOptions, setNewFieldOptions] = useState('');

    const handleEditClick = (product) => {
        const [startTime = '', endTime = ''] = product.additionally.opening_hours
            ? product.additionally.opening_hours.replace('C ', '').split(' до ')
            : [];
        setEditProductData({
            ...product,
            additionally: {
                ...product.additionally,
                opening_hours_start: startTime,
                opening_hours_end: endTime,
            },
        });
        setNewAddress('');
    };

    const handleSave = async () => {
        const updatedProduct = {
            ...editProductData,
            additionally: {
                ...editProductData.additionally,
                opening_hours: `C ${editProductData.additionally.opening_hours_start} до ${editProductData.additionally.opening_hours_end}`,
            },
        };

        try {
            await editProduct(updatedProduct);
            setEditProductData(null);
            setSnackbarOpen(true);
            dispatch(fetchAllProducts());
        } catch (error) {
            console.error('Ошибка при обновлении продукта:', error);
            alert('Не удалось обновить продукт!');
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleMapModalOpen = () => {
        setIsMapModalOpen(true);
    };

    const handleMapModalClose = (newAddressData) => {
        setIsMapModalOpen(false);
        if (newAddressData) {
            setNewAddress(newAddressData.address);
            setEditProductData(prev => ({
                ...prev,
                additionally: {
                    ...prev.additionally,
                    coordinates: newAddressData.coordinates,
                    address: newAddressData.address,
                },
            }));
        }
    };

    const renderCustomFieldEditor = (field, index) => (
        <Paper
            key={field.id || index}
            elevation={1}
            sx={{ p: 2, mb: 2, display: 'flex', flexDirection: 'column', gap: 2, borderRadius: '12px'  }}
        >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <Typography variant="subtitle1">
                    {field.label || `Поле ${index + 1}`}
                </Typography>
                <IconButton
                    onClick={() => {
                        if (window.confirm('Удалить это поле?')) {
                            setEditProductData(prev => {
                                const newFields = prev.additionally.custom_fields.filter((_, i) => i !== index);
                                return {
                                    ...prev,
                                    additionally: { ...prev.additionally, custom_fields: newFields },
                                };
                            });
                        }
                    }}
                    color="error"
                >
                    <DeleteIcon />
                </IconButton>
            </Box>
            <TextField
                label="Название поля"
                value={field.label}
                onChange={(e) => {
                    const newLabel = e.target.value;
                    setEditProductData(prev => {
                        const newFields = [...prev.additionally.custom_fields];
                        newFields[index] = { ...newFields[index], label: newLabel };
                        return {
                            ...prev,
                            additionally: { ...prev.additionally, custom_fields: newFields },
                        };
                    });
                }}
                fullWidth
            />
            <FormControl fullWidth>
                <InputLabel id={`field-type-label-${index}`}>Тип поля</InputLabel>
                <Select
                    labelId={`field-type-label-${index}`}
                    value={field.type}
                    label="Тип поля"
                    onChange={(e) => {
                        const newType = e.target.value;
                        if (newType !== field.type) {
                            if (
                                window.confirm(
                                    'Изменение типа поля приведет к потере существующих данных. Продолжить?'
                                )
                            ) {
                                setEditProductData(prev => {
                                    const newFields = [...prev.additionally.custom_fields];
                                    newFields[index] = {
                                        ...newFields[index],
                                        type: newType,
                                        ...(newType !== 'list' ? { options: [] } : {}),
                                    };
                                    return {
                                        ...prev,
                                        additionally: { ...prev.additionally, custom_fields: newFields },
                                    };
                                });
                            }
                        }
                    }}
                >
                    {FIELD_TYPES.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            {field.type === 'list' && (
                <TextField
                    label="Опции (через запятую)"
                    value={Array.isArray(field.options) ? field.options.join(', ') : ''}
                    onChange={(e) => {
                        const newOptions = e.target.value
                            .split(',')
                            .map(opt => opt.trim())
                            .filter(opt => opt);
                        setEditProductData(prev => {
                            const newFields = [...prev.additionally.custom_fields];
                            newFields[index] = { ...newFields[index], options: newOptions };
                            return {
                                ...prev,
                                additionally: { ...prev.additionally, custom_fields: newFields },
                            };
                        });
                    }}
                    fullWidth
                />
            )}
        </Paper>
    );

    return (
        <AnimatedBox delay={0.1}>
            <Container maxWidth="md" sx={{ pt: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant="h6" gutterBottom>
                        Помещения
                    </Typography>
                    <IconButton aria-label="add room" onClick={() => navigate('/adminpanel/add-product')}>
                        <AddIcon />
                    </IconButton>
                </Box>
                <Grid container spacing={3}>
                    {products.map(product => (
                        <Grid item xs={12} sm={6} md={4} key={product.id}>
                            <Card sx={{ borderRadius: '16px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}>
                                <CardMedia
                                    component="img"
                                    image={product.image_link}
                                    alt={product.label}
                                    sx={{ height: 150, objectFit: 'cover' }}
                                />
                                <CardContent>
                                    <Typography variant="h6" component="div" gutterBottom>
                                        {product.label}
                                    </Typography>
                                    {product.additionally && (
                                        <Box>
                                            {product.additionally.address && (
                                                <Typography variant="body2" color="text.secondary">
                                                    Адрес: {product.additionally.address}
                                                </Typography>
                                            )}
                                            {product.additionally.capacity && (
                                                <Typography variant="body2" color="text.secondary">
                                                    Вместимость: {product.additionally.capacity}
                                                </Typography>
                                            )}
                                            {product.additionally.opening_hours && (
                                                <Typography variant="body2" color="text.secondary">
                                                    Время работы: {product.additionally.opening_hours}
                                                </Typography>
                                            )}
                                        </Box>
                                    )}
                                    <Typography variant="body2" color="text.secondary">
                                        Дополнительно: {product.description || 'Описание отсутствует'}
                                    </Typography>
                                </CardContent>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    sx={{ borderRadius: '0 0 12px 12px', mt: 1 }}
                                    onClick={() => handleEditClick(product)}
                                >
                                    Редактировать
                                </Button>
                            </Card>
                        </Grid>
                    ))}
                </Grid>

                {editProductData && (
                    <Modal open={Boolean(editProductData)} onClose={() => setEditProductData(null)}>
                        <Box
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                bgcolor: 'background.paper',
                                boxShadow: 24,
                                p: 4,
                                borderRadius: '8px',
                                width: '90%',
                                maxWidth: '500px',
                                maxHeight: '90vh',
                                overflowY: 'auto',
                            }}
                        >
                            <Typography variant="h6" mb={2}>
                                Редактировать продукт
                            </Typography>
                            <TextField
                                fullWidth
                                label="Название"
                                value={editProductData.label}
                                onChange={(e) =>
                                    setEditProductData({ ...editProductData, label: e.target.value })
                                }
                                sx={{ mb: 2 }}
                            />
                            <TextField
                                fullWidth
                                label="Дополнительно"
                                value={editProductData.description}
                                onChange={(e) =>
                                    setEditProductData({ ...editProductData, description: e.target.value })
                                }
                                sx={{ mb: 2 }}
                            />
                            <TextField
                                fullWidth
                                label="Вместимость"
                                value={editProductData.additionally.capacity || ''}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (/^\d*$/.test(value)) {
                                        setEditProductData(prev => ({
                                            ...prev,
                                            additionally: { ...prev.additionally, capacity: value },
                                        }));
                                    }
                                }}
                                sx={{ mb: 2 }}
                            />
                            <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="start-time-label">Начало работы</InputLabel>
                                    <Select
                                        labelId="start-time-label"
                                        value={editProductData.additionally.opening_hours_start || ''}
                                        label="Начало работы"
                                        onChange={(e) =>
                                            setEditProductData(prev => ({
                                                ...prev,
                                                additionally: {
                                                    ...prev.additionally,
                                                    opening_hours_start: e.target.value,
                                                },
                                            }))
                                        }
                                    >
                                        {TIME_OPTIONS.map(time => (
                                            <MenuItem key={time} value={time}>
                                                {time}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth>
                                    <InputLabel id="end-time-label">Окончание работы</InputLabel>
                                    <Select
                                        labelId="end-time-label"
                                        value={editProductData.additionally.opening_hours_end || ''}
                                        label="Окончание работы"
                                        onChange={(e) =>
                                            setEditProductData(prev => ({
                                                ...prev,
                                                additionally: {
                                                    ...prev.additionally,
                                                    opening_hours_end: e.target.value,
                                                },
                                            }))
                                        }
                                    >
                                        {TIME_OPTIONS.map(time => (
                                            <MenuItem key={time} value={time}>
                                                {time}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Button variant="outlined" fullWidth onClick={handleMapModalOpen} sx={{ mb: 2 }}>
                                Изменить адрес
                            </Button>
                            {newAddress && (
                                <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                                    Новый адрес: {newAddress}
                                </Typography>
                            )}

                            <Box sx={{ mt: 2 }}>
                                {editProductData.additionally.custom_fields &&
                                    editProductData.additionally.custom_fields.map((field, index) =>
                                        renderCustomFieldEditor(field, index)
                                    )}
                            </Box>

                            <Paper elevation={2} sx={{ p: 2, mt: 2, borderRadius: '12px' }}>
                                <Typography variant="subtitle1" gutterBottom>
                                    Добавить новое поле
                                </Typography>
                                <TextField
                                    label="Название поля"
                                    value={newFieldLabel}
                                    onChange={(e) => setNewFieldLabel(e.target.value)}
                                    fullWidth
                                    sx={{ mb: 2 }}
                                />
                                <FormControl fullWidth sx={{ mb: 2 }}>
                                    <InputLabel id="new-field-type-label">Тип поля</InputLabel>
                                    <Select
                                        labelId="new-field-type-label"
                                        value={newFieldType}
                                        label="Тип поля"
                                        onChange={(e) => setNewFieldType(e.target.value)}
                                    >
                                        {FIELD_TYPES.map(option => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                {newFieldType === 'list' && (
                                    <>
                                        <FormControl fullWidth sx={{ mb: 2 }}>
                                            <InputLabel id="new-list-mode-label">Тип списка</InputLabel>
                                            <Select
                                                labelId="new-list-mode-label"
                                                value={newFieldListMode}
                                                label="Тип списка"
                                                onChange={(e) => setNewFieldListMode(e.target.value)}
                                            >
                                                {LIST_MODES.map(option => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <TextField
                                            label="Опции (через запятую)"
                                            value={newFieldOptions}
                                            onChange={(e) => setNewFieldOptions(e.target.value)}
                                            fullWidth
                                            sx={{ mb: 2 }}
                                        />
                                    </>
                                )}
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        if (!newFieldLabel || !newFieldType) return;
                                        const newField = {
                                            id: Date.now(),
                                            label: newFieldLabel,
                                            type: newFieldType,
                                            ...(newFieldType === 'list' && {
                                                listMode: newFieldListMode,
                                                options: newFieldOptions
                                                    .split(',')
                                                    .map(opt => opt.trim())
                                                    .filter(opt => opt),
                                            }),
                                        };
                                        setEditProductData(prev => {
                                            const currentFields = prev.additionally.custom_fields || [];
                                            return {
                                                ...prev,
                                                additionally: {
                                                    ...prev.additionally,
                                                    custom_fields: [...currentFields, newField],
                                                },
                                            };
                                        });
                                        setNewFieldLabel('');
                                        setNewFieldType('');
                                        setNewFieldListMode('single');
                                        setNewFieldOptions('');
                                    }}
                                    fullWidth
                                >
                                    Добавить поле
                                </Button>
                            </Paper>

                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={handleSave}
                                sx={{ mt: 2 }}
                            >
                                Сохранить
                            </Button>
                        </Box>
                    </Modal>
                )}

                {isMapModalOpen && (
                    <MapModal
                        open={isMapModalOpen}
                        onClose={handleMapModalClose}
                        currentAddress={editProductData?.additionally?.address || ''}
                        currentCoordinates={
                            editProductData?.additionally?.coordinates || '55.751244,37.618423'
                        }
                    />
                )}

                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={1500}
                    onClose={handleSnackbarClose}
                    message="Продукт успешно обновлён!"
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                />
            </Container>
        </AnimatedBox>
    );
};

export default ProductList;
